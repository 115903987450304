import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { NativeBiometric } from "capacitor-native-biometric";
import {FingerprintOutlined} from "@material-ui/icons";


/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

const BiometryType = {
  NONE : 0,
  TOUCH_ID : 1,
  FACE_ID : 2,
  FINGERPRINT : 3,
  FACE_AUTHENTICATION : 4,
  IRIS_AUTHENTICATION : 5
}

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      /*.email("Wrong email format")*/
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      /*.min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")*/
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.username, values.password)
          .then(({ data }) => {
            disableLoading();
            props.login(data);
            saveKeychain(values.username,values.password)
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  // Check if biometrics are available and which type is supported
    const [nativeBiometricAvailable, setNativeBiometricAvailable] = React.useState(false)
    const [nativeBiometricFaceID, setNativeBiometricFaceId] = React.useState(false)

  React.useEffect(()=> {
      NativeBiometric.isAvailable().then(
          (result: AvailableResult) => {
              const isAvailable = result.isAvailable;
              const isFaceId = result.biometryType === BiometryType.FACE_ID;
              setNativeBiometricAvailable(isAvailable)
              setNativeBiometricFaceId(isFaceId)
              console.log("isFaceId", isFaceId)

          },
      )
  },[])

  const getBiometricCredentials = () => {
      if (nativeBiometricAvailable) {
          // Get user's credentials
          NativeBiometric.getCredentials({
              server: "staff.crystalproductions.com.au",
          }).then((credentials: Credentials) => {
              // Authenticate using biometrics before logging the user in
              NativeBiometric.verifyIdentity({
                  reason: "For easy log in",
                  title: "Log in",
                  subtitle: "Maybe add subtitle here?",
                  description: "Maybe a description too?",
              }).then(
                  () => {
                      // Authentication successful
                      formik.setFieldValue("username", credentials.username, true)
                      formik.setFieldValue("password", credentials.password, true)
                      formik.submitForm()
                      console.log("this.login(", credentials.password);

                  },
                  (error) => {
                      // Failed to authenticate
                  }
              );
          }).catch(e => {
              console.log("could not get credentials", e)
          });
      }
  }


  const saveKeychain = (username, password) => {
      NativeBiometric.isAvailable().then(
          // Save user's credentials
          NativeBiometric.setCredentials({
              username,
              password,
              server: "staff.crystalproductions.com.au",
          }).then()
      )
  }
  const deleteKeychain = () => {
      NativeBiometric.isAvailable().then(
          // Delete user's credentials
          NativeBiometric.deleteCredentials({
              server: "staff.crystalproductions.com.au",
          }).then()
      )
  }


  return (
      <div className="login-form login-signin" id="kt_login_signin_form">
          {/* begin::Head */}
          <div className="text-center mb-10 mb-lg-20">
              <h3 className="font-size-h1">
                  <FormattedMessage id="AUTH.LOGIN.TITLE"/>
              </h3>
              <p className="text-muted font-weight-bold">
                  Enter your username and password
              </p>
          </div>
          {/* end::Head */}

          {/*begin::Form*/}
          <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework"
          >
              {formik.status ? (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                      <div className="alert-text font-weight-bold">{formik.status}</div>
                  </div>
              ) : (
                  <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
                      <div className="alert-text ">
                          Use your flex account details provided to you to log in.
                      </div>
                  </div>
              )}

              <div className="form-group fv-plugins-icon-container">
                  <input
                      placeholder="Username"
                      type="textfield"
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "email"
                      )}`}
                      name="username"
                      {...formik.getFieldProps("username")}
                  />
                  {formik.touched.username && formik.errors.username ? (
                      <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{formik.errors.username}</div>
                      </div>
                  ) : null}
              </div>
              <div className="form-group fv-plugins-icon-container">
                  <input
                      placeholder="Password"
                      type="password"
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "password"
                      )}`}
                      name="password"
                      {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password ? (
                      <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{formik.errors.password}</div>
                      </div>
                  ) : null}
              </div>
              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <Link
                      to="/auth/forgot-password"
                      className="text-dark-50 text-hover-primary my-3 mr-2"
                      id="kt_login_forgot"
                  >
                      <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON"/>
                  </Link>
                  {nativeBiometricAvailable && <button
                      id="kt_login_signin_bioMetrics"
                      type="button"
                      onClick={getBiometricCredentials}
                      disabled={formik.isSubmitting}
                      className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                  ><FingerprintOutlined size={"large"}/></button>
                  }
                  <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={formik.isSubmitting}
                      className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                  >
                      <span>Sign In</span>
                      {loading && <span className="ml-3 spinner spinner-white"></span>}
                  </button>
              </div>
          </form>
          {/*end::Form*/}
      </div>
  );
  }

export default injectIntl(connect(null, auth.actions)(Login));
